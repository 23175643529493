@import '../../variables.scss';

.homeContent {
    .homeContainer {
        position: relative;
        padding-bottom: calc($appPaddingBottom);

        .mascot {
            height: vw(300);
            width: vw(300);
            opacity: 0.15;
            position: fixed;
            bottom: vw(100);
            left: 50%;
            transform: translateX(-50%);
        }

        .content {
            z-index: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .welcome {
                width: vw(320);
                height: vw(80.55);
                margin-bottom: vw(80);
            }

            .ticketNum {
                font-weight: 300;
                font-size: vw(12);
                line-height: vw(15);
                margin-bottom: vw(10);
            }

            h1 {
                font-weight: 700;
                font-size: vw(20);
                line-height: vw(24);
                margin-bottom: vw(64);
            }

            h2 {
                margin-bottom: vw(100);
                font-weight: 700;
                font-size: vw(18);
                line-height: vw(22);
                color: #FFFFFF;

                a {
                    color: white;
                }
            }

            h3 {
                font-style: normal;
                font-weight: 300;
                font-size: vw(36);
                line-height: vw(44);
                color: #FFFFFF;
                margin-bottom: vw(30);

                span {
                    font-size: vw(20);
                }
            }
        }
    }
}