@import '../../variables.scss';

.donationsContent {
    .donationsContainer {
        position: relative;
        padding-bottom: calc($appPaddingBottom);

        .mascot {
            height: vw(300);
            width: vw(300);
            opacity: 0.15;
            position: fixed;
            bottom: vw(100);
            z-index: 0;
            left: 50%;
            transform: translateX(-50%)
        }

        .content {
            z-index: 1;
            width: 100%;

            header {
                text-align: center;
                font-weight: 700;
                font-size: vw(20);
                line-height: vw(24);
                margin-bottom: vw(10) !important;
                position: relative;
                width: 100%;

                img {
                    position: absolute;
                    left: 0;
                    width: vw(24);
                    height: vw(24);
                }
            }

            h4 {
                text-align: center;
                margin-bottom: vw(32);

            }

            .paymentSelector {
                display: flex;
                flex-direction: column;
                gap: vw(20);

                button {
                    height: vw(100);
                    width: vw(320);
                }
            }

            .donationPool {
                color: black;
                padding: vw(20) vw(30);
                background: white;
                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                border-radius: 16px;

                h3 {
                    font-weight: 700;
                    font-size: vw(16);
                    line-height: vw(20);
                    text-align: center;
                    color: $blue;
                    margin-bottom: vw(15);
                }

                p {
                    font-weight: 400;
                    font-size: vw(12);
                    line-height: vw(14);
                    text-align: center;
                    margin-bottom: vw(26);
                }

                form {
                    display: flex;
                    flex-direction: column;
                    gap: vw(20);
                }

                .paymentMethods {
                    display: flex;
                    flex-direction: column;
                    gap: vw(10);

                    .paymentMethod {
                        border-radius: 16px;
                        // border: 1px solid $blue;
                        background: $blue;
                        color: white;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: vw(10) vw(50);
                        transition: all 0.25s;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

                        h6 {
                            text-transform: uppercase;
                            font-size: vw(14);
                        }

                        p {
                            margin: 0;
                            padding: 0;
                            font-size: vw(14);
                        }

                    }

                    .active {
                        background: #BDE1FF;
                        color: $blue;
                    }
                }
            }

            .offline {
                // height: 100%;
                width: 100%;
                font-weight: 700;
                font-size: 20px;
                line-height: 24px;
                text-align: center;

            }

            .paid {
                text-align: center;
            }

        }

    }
}

.error::placeholder {
    color: red;
}