@import url("https://use.typekit.net/qsg5vhp.css");
@import './variables.scss';

html,
body {
    background: $blue;
}

* {
    margin: 0;
    padding: 0;
}

.container {
    min-height: 100vh;
    background: $blue;
    padding: $pagePaddingY $pagePaddingX;
    color: white;
    font-family: montserrat, sans-serif !important;
    font-weight: 400;
    font-style: normal;
    display: flex;
    flex-direction: column;
    align-items: center;
}

input {
    width: 100%;
    font-weight: 400;
    font-size: vw(14);
    line-height: vw(20);
    color: black;
    background: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid $blue;
    padding: vw(10) 0;
    text-align: center;
    border-radius: 0;

}

input::placeholder {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
}

button {
    width: 100%;
    height: vw(40);
    border-radius: 8px;
    text-transform: uppercase;
    color: $blue;
    background: $sand;
    font-weight: 700;
    font-size: vw(12);
    line-height: vw(15);
    transition: all 0.25s;
}

button:disabled {
    opacity: 0.5;
}

ion-tab-bar {
    * {
        box-sizing: border-box !important;
    }

    height: vw(75);
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-bottom: vw(20);


    ion-tab-button {
        background: transparent;
        width: 100%;
        margin: 0 vw(10);
        border-radius: 15px;
        transition: background-color 0.15s;
        position: relative;

        ion-icon {
            height: vw(40);
            width: vw(40);
        }
    }

    .tab-selected:before {
        content: ' ';
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
        width: vw(60);
        height: vw(60);
        opacity: 0.5;
        background: #0C53A080;
        background-repeat: no-repeat;
        background-position: 50% 0;
        background-size: cover;
    }
}