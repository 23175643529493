@import "../../variables.scss";

.loginContainer {
    .sdcLogo {
        width: vw(190);
    }

    h2 {
        font-weight: 700;
        font-size: vw(16);
        line-height: vw(20);
        text-align: center;
        width: vw(270);
        margin-top: vw(30);
        margin-bottom: vw(100);
    }

    .loginContent {
        width: 100%;
        padding: vw(30);
        background-color: rgba(255, 255, 255, 0.8);
        box-shadow: 2px 2px 3px rgba(255, 255, 255, 0.25);
        border-radius: 16px;
        position: relative;
        z-index: 1;
    }

    .mascot {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
        opacity: 0.1;
    }

    input {
        margin-bottom: vw(30);
    }

    .getCode {
        margin-bottom: vw(60);
    }

}