@import '../../variables.scss';

.donationViewContent {
    .donationViewContainer {
        position: relative;
        padding-bottom: calc($appPaddingBottom + vw(50));

        .mascot {
            height: vw(300);
            width: vw(300);
            opacity: 0.15;
            position: fixed;
            bottom: vw(100);
            z-index: 0;
            left: 50%;
            transform: translateX(-50%);
        }

        .content {
            z-index: 1;
            width: 100%;

            header {
                text-align: center;
                font-weight: 700;
                font-size: vw(20);
                line-height: vw(24);
                margin-bottom: vw(32);
                position: relative;
                width: 100%;
            }

            .searchResults {
                padding: vw(20);
                background: white;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 16px;
                margin-top: vw(40);
                gap: vw(10);

                h2 {
                    color: $blue;
                    margin-bottom: vw(20);
                    font-style: normal;
                    font-weight: 700;
                    font-size: vw(16);
                    line-height: vw(20);
                }

                .attendee {
                    color: black;
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    // padding: vw(10) 0;
                    background: $sand;
                    padding: vw(10) vw(20);
                    border-radius: 16px;

                    h3 {
                        font-weight: 700;
                        font-size: vw(16);
                        line-height: vw(20);
                    }

                    p {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        font-weight: 400;
                        font-size: vw(12);
                        line-height: vw(17);
                    }

                    .registerNow {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        h4 {
                            font-weight: 700;
                            font-size: vw(16);
                            line-height: vw(20);
                        }

                        a {
                            width: vw(90);
                            height: vw(30);
                            background: $sand;
                            border-radius: 16px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: $blue;
                            font-weight: 700;
                            font-size: vw(12);
                            line-height: vw(15);
                            text-transform: uppercase;
                        }
                    }
                }

            }
        }
    }
}